import React from 'react'
import Layout from '../components/layout';

const AboutPage = () => (
    <Layout>
        <div>
            <p>Experienced Developer with 7+ years of hands on experience in web and application development as well as leading projects through all phases of Software Development Life Cycle. Effective Lead Developer who communicates with team members while developing functional requirements documents. Proven experience in developing websites and web applications for Healthcare, Travel, Meeting, and Incentive companies. Experienced in working with Java, Angular, Node.js, ASP.net, PHP, JSP, Servlet, MongoDB, SQL, and MySQL.</p>

        </div>
    </Layout>
)


export default AboutPage

